import React from 'react';
import { useState, useEffect } from 'react';

import { AiFillInstagram } from 'react-icons/ai';
import { AiFillFacebook }  from 'react-icons/ai';
import { AiOutlineTwitter }  from 'react-icons/ai';
import { AiFillLinkedin }  from 'react-icons/ai';

export default function BottomBar() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {

      const changeWidth = () => {
        setScreenWidth(window.innerWidth);
      }
  
      window.addEventListener('resize', changeWidth)

      return () => {
          window.removeEventListener('resize', changeWidth)
      }
  
    }, []);
  return (
    <div className='footer'>
        All Rights Reserved. Site design © 2022 Techsulting Inc. 
        {(screenWidth < 600) && <br/>}
        <a href="https://www.instagram.com/techsulting/"> <AiFillInstagram/> </a>
        <a href="https://www.instagram.com/techsulting/"> <AiFillFacebook/></a>
        <a href="https://www.instagram.com/techsulting/"> <AiOutlineTwitter/></a>
        <a href="https://www.instagram.com/techsulting/"> <AiFillLinkedin/></a>
    </div>
  )
}
