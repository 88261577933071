import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from './images/iOSAndroidpic.png';
import img2 from './images/softwaredevpic.jpeg';
import img3 from './images/webdevpic.jpeg';
import squarespacepic from './images/squarespacepic.jpg';
import googlepic from './images/googlepic.jpg';
import backendpic from './images/backendpic.jpg';
import appstorepic from './images/appstore.jpg';
import calcpic from './images/calcpic.jpg';
import tutoringpic from './images/tutoringpic.jpg';

export default function  ServicesPage() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const navigate = useNavigate();
  const handleClickContact = () => {
    navigate('/contact');
  };
  return (
    <div className='standard-frame'> 
      <div className="headline padded-top">
          <p><b>Web Services</b></p>
      </div>
      <div className='flex-container padded-below med-height'>
        <div className='flex-item-70'>
          <p><b>Custom Website Development</b></p>
          <p>
            We create custom, personalized websites for your needs. We are patient throughout the whole process, 
            and will work with you to create the website you need for yourself, your brand, or your business.
          </p>
        </div>
        <div className='flex-item-30'>
          <img src={img3} alt='' width='100%' height='100%'/>
        </div>
      </div>
      <div className='flex-container padded-below med-height'>
        <div className='flex-item-30'>
          <img src={squarespacepic} alt='' width='100%' height='100%'/>
        </div>
        <div className='flex-item-70 padded-left'>
          <p><b>Squarespace/Wix Design</b></p>
          <p>
            Don't want a custom website from scratch? We can also design your Squarespace or Wix website.
          </p>
        </div>
      </div>
      <div className='flex-container padded-below med-height'>
        <div className='flex-item-70'>
          <p><b>Web Services Consulting</b></p>
          <p>
            Not privvy to all of the web APIs and programs needed to run your business? Let us consult you on several popular
            services including Firebase, Google Cloud, Google AdSense, and many more.
          </p>
        </div>
        <div className='flex-item-30'>
          <img src={googlepic} alt='' width='100%' height='100%'/>
        </div>
      </div>
      <hr className='line-break'/>
      <div className="headline padded-top">
          <p><b>Mobile Services</b></p>
      </div>
      <div className='flex-container padded-below'>
        <div className='flex-item-30'>
          <img src={img1} alt='' width='100%' height='100%'/>
        </div>
        <div className='flex-item-70 padded-left'>
          <p><b>Cross-Platform App Development</b></p>
          <p>
          App Development services include iOS (Apple), and Android (Google) platforms.
          We can build you a custom, personalized application at an affordable price.
          </p>
        </div>
      </div>
      <div className='flex-container padded-below med-height'>
       <div className='flex-item-70'>
          <p><b>Backend Support</b></p>
          <p>
            This service will connect your application to backend servers.
          </p>
        </div>
        <div className='flex-item-30'>
          <img src={backendpic} alt='' width='100%' height='100%'/>
        </div>
      </div>
      <div className='flex-container padded-below med-height'>
        <div className='flex-item-30'>
          <img src={appstorepic} alt='' width='100%' height='100%'/>
        </div>
        <div className='flex-item-70 padded-left'>
          <p><b>App Store SEO</b></p>
          <p>
            Search Engine Optimization is important to get potential customers to view your app. This 
            service will use tried and tested techniques to ensure your content is seen on either the Apple App 
            Store or the Google Play Store.
          </p>
        </div>
      </div>
      <hr className='line-break'/>
      <div className="headline padded-top">
          <p><b>Miscellaneous</b></p>
      </div>
      <div className='flex-container padded-below med-height'>
       <div className='flex-item-70'>
          <p><b>Scripting/Automation</b></p>
          <p>
            Automate basic tasks that you do online everyday with a simple program. We use popular WebScraping modules 
            and APIs to automate tasks in order to improve productivity. 
          </p>
        </div>
        <div className='flex-item-30'>
          <img src={img2} alt='' width='100%' height='100%'/>
        </div>
      </div>
      <div className='flex-container padded-below med-height'>
        <div className='flex-item-30'>
          <img src={calcpic} alt='' width='100%' height='100%'/>
        </div>
        <div className='flex-item-70 padded-left'>
          <p><b>Business Calculators/Desktop Programs</b></p>
          <p>
            Have you been doing calculations by hand? Let us design and create and personalized calculator for your business/
            personal needs. We can even connect it online to be able to grab data continously.
          </p>
          <p>
            We can also build custom business desktop applications. These applications can help you manage and organize 
            your day-to-day operations.
          </p>
        </div>
      </div>
      <div className='flex-container padded-below med-height'>
       <div className='flex-item-70'>
          <p><b>Tutoring/Seminars</b></p>
          <p>
            Want to learn a new programing language? Let us teach you through personalized lessons. Languages we teach 
            include Javascript, Java, Python, HTML, and C. We can also help you study for exams such as APCS and Comptia +
          </p>
          <p>
            We can also conduct seminars to your organization on technoligcal literacy and similar topics.
          </p>
        </div>
        <div className='flex-item-30'>
          <img src={tutoringpic} alt='' width='100%' height='100%'/>
        </div>
      </div>
      <hr className='line-break'/>
      <div className='centered padded-top padded-below'>
        <h1>Interested? Contact Us</h1>
        <button type="button" className="btn-home centered" onClick={handleClickContact}>
          Free Quote
        </button>
      </div>
    </div>
  )
}


