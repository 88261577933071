import "./styles.css"
import React, {useState, useEffect} from 'react';
import img from "./images/techsulting.png"

export default function Navbar(){
    const [toggleMenu, setToggleMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
    };

    useEffect(() => {

        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    
      }, []);

    return (
        <div>
        <nav className="nav">
                <div>
                    <a href="/" className="site-title"><img src={img} height="50rem" width="50rem" alt="techsulting"/> </a>
                    <button className="nav-btn" onClick={toggleNav}>__<br/>__<br/>__</button>
                </div>
                <div>
                    {((toggleMenu || screenWidth > 600) && (
                        <ul className="nav-list">
                            <li className="nav-items">
                                <a href="about"><b>About</b></a>
                            </li>
                            <li className="nav-items">
                            <a href="services"><b>Services</b></a>
                            </li>
                            <li className="nav-items">
                                <a href="contact"><b>Contact Us</b></a>
                            </li>
                        </ul>
                    ))}
                </div>
            </nav>
        </div>
    
    )
}