import './App.css';
import Navbar from './Navbar';
import {Route, Routes} from 'react-router-dom';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import ContactPage from './ContactPage';
import ServicesPage from './ServicesPage';
import BottomBar from './BottomBar';

function App() {
  return (
    <>
      <Navbar/> 
      <div className="container">
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/contact" element={<ContactPage/>}/>
          <Route path="/services" element={<ServicesPage/>}/>
        </Routes>
        <BottomBar/>
      </div>
    </>
    );
}

export default App;
