import React from 'react';
import {useNavigate} from 'react-router-dom';
import './styles.css';
import homepageimg1 from './images/homepagepic1.jpg';
import homepageimg2 from './images/homepagepic2.jpg';
import homepageimg3 from './images/homepagepic3.jpg';

export default function HomePage() {
  const navigate = useNavigate();
  const handleClickServices = () => {
    navigate('/services');
  };
  const handleClickAbout = () => {
    navigate('/about');
  };
  const handleClickContact = () => {
    navigate('/contact');
  };
  return (
    <div className='standard-frame'>
        <div className='defn-box'>
          <div className='defn'>
            <p className='larger-text'>techsulting</p>
            <div className='small-label'>(verb)</div>
            <br/>
            <br/>
          </div>
          <p>1. technology + consulting</p>
          <p>(see also:&nbsp;<span className="bold-italic">technology consulting</span>)</p>
        </div>
        <div className="headline">
          <p><b>Digitalize your business operations</b></p>
        </div>
        <div>
          <p>
          <b>
            Techsulting provides all of the software services you need to elevate your entreprise
            into the modern digital landscape!        
          </b>
          </p>
          <p>
            Let us bring your business to the 21st century. Techsulting is a new company designed to work with 
            small companies and individuals to carter their technology needs. Located in Charleston, SC, we are
            proud to work inside the Lowcountry but also with clients around the country and world!
          </p>
        </div>
        <div className='flex-container'>
          <div className='flex-item'>
              <img src={homepageimg1} alt="pic" width="100%" height="100%"/>
          </div>
          <div className='flex-item'>
            <img src={homepageimg2} alt="pic" width="100%" height="100%"/>
          </div>
          <div className='flex-item'>
            <img src={homepageimg3} alt="pic" width="100%" height="100%"/>
          </div>
        </div>
        <hr className='line-break'/>
        <div className="headline">
            <p>Services</p>
        </div>
        <div>
          <p>
            When you work with us, you are getting top tier technology services for your company. The services we offer include
            website development, mobile app development, scripting & automation, tutoring, and custom business calculators/programs.
            However, we are a flexible company who is flexible to our client needs, so we will consult with you for your specific needs!
          </p>
        </div>
        <button type="button" className="btn-home" onClick={handleClickServices}>
              Browse All Services
        </button>
        <hr className='line-break'/>
        <div className="headline">
            <p>About Us</p>
        </div>
        <div className='after-headline'>
          <p>
          <b>
            Why us?       
          </b>
          </p>
          <p>
            At Techsulting, each client has our undivided focus and respect. We are a young, hungry company
            that is eager to make a name for ourselves. Bigger companies might have a larger portfolio, but they will 
            not take you as priority like we will. Other freelance developers might be available, but they will not give you 
            the superior service and communication like we will! Techsulting is a vetetan owned, black owned company that is 
            excited to get to work!
          </p>
          <button type="button" className="btn-home" onClick={handleClickAbout}>
              More on Techsulting
          </button>
          <hr className='line-break'/>
        </div>
        <div className='centered'>
            <h1>Why wait? Hire us today</h1>
            <button type="button" className="btn-home centered" onClick={handleClickContact}>
              Free Quote
            </button>
        </div>
    </div>
  )
}



