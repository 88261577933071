import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import contactuspic from './images/contactus.jpg';
import techsultinglogo from './images/techsulting.png'


export default function ContactPage() {
  const form = useRef();

  const handleSubmit = (e) => {
    //event.preventDefault();
    //alert(`The name you entered was: ${e.name} ${e.email} ${e.req}`);
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    emailjs.sendForm('service_lj33253', 'template_9hgso9k', form.current, '9NFsaSMrz3RmLhrOv')
      .then((result) => {
          window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });
  };

  
  return (
    <div className='standard-frame'>
      <div className='flex-container padded-below padded-top'>
        <div className='flex-item-50'>
          <div className="headline">
              <p>Request A Quote Today!</p>
          </div>
        </div>
        <div className='flex-item-50'>
          <img src={contactuspic} alt='' width='100%' height='100%'/>
        </div>
      </div>
      <div className='flex-container padded-below padded-top'>
        <div className='flex-item-50' style={border}>
          <img src={techsultinglogo} alt='' width='100%' height='100%'/>
        </div>
        <div className='flex-item-50'>
          <div className='contact-form'>
            <form ref={form} onSubmit={handleSubmit}>
              <div>
                <p>Name (required)</p>
                <input type="text" name="user_name" style={styleInput}/*value={name} onChange={(e) => setName(e.target.value)}*/ />
              </div>
              <div>
                <p>Phone Number (required)</p>
                <input type="phone" name="user_number" style={styleInput}/*value={name} onChange={(e) => setName(e.target.value)}*/ />
              </div>
              <div>
                <p>Email (required)</p>
                <input type="email" name="user_email" style={styleInput}/*value={email} onChange={(e) => setEmail(e.target.value)}*/ />
              </div>
              <div>
                  <p>Request</p>
                  <textarea /*value={req}*/ name="message" style={styleInput}/*onChange={(e) => setReq(e.target.value)}*/  />
              </div>
              <input name="submit" type="submit" value="Submit" className='btn-home' style={submitStyle}/>
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}

const submitStyle = {
    width: "100%"
};

const styleInput = {
  width: "50%",
};

const border = {
  border: "3px solid black"
}