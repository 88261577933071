import React, { useEffect } from 'react'
import './styles.css'
import founderpic from './images/website-personal.jpeg'
import charlestonpic from './images/charlestonpic.jpg'
export default function AboutPage() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (
    <div className='standard-frame'>
        <div className='headline'>
            <p><b>Origin</b></p>
        </div>
        <div className='flex-container'>
            <div className='flex-item-70 padded-right'>
                <p>
                    Techsulting was started to help bridge the gap in technology. We are a company 
                    that provides computer software, strategy, and solutions to small businesses, proprietorships, 
                    and individuals in order to improve performance, discover new clients, and to expand 
                    opportunities. Through patient and personable service, we offer clients the services needed 
                    to modernize their operations. Located in beautiful Charleston, SC, we strive to serve the local
                    community with professional technology consulting.
                </p>
            </div>
            <div className='flex-item-30'>
                <img src={charlestonpic} alt='' width="100%" height="100%"/>
            </div>
        </div>
        <hr className='line-break'/>
        <div className='headline'>
            <p><b>Why Us</b></p>
        </div>
        <div className='centered'>
            <p><b>Techsulting prides ourselves on 3 main business principals: </b></p>
        </div>
        <div className='flex-container'>
          <div className='flex-item'>
              <div className='centered padded-5'>
                <p><u><b>Service</b></u></p>
                <p>
                    With us at your side, we pledge to give you professional service at a fraction of the price.
                    We will thoroughly plan out your project to make sure your product comes out on time. With us being 
                    a small company, we are able to be flexible and adjust to your needs as time goes on.
                </p>
              </div>
          </div>
          <div className='flex-item'>
                <div className='centered padded-5'>
                    <p><u><b>Communication</b></u></p>
                    <p>
                        We promise to give you quick and easy communication to make sure that we are on the page throughout.
                        Unlike bigger compnaies who often have tens if not hundreds of clients on contract, Techsulting is a small
                        company with a small client list which allows us to give timely communication. 
                    </p>
                </div>
          </div>
          <div className='flex-item'>
                <div className='centered padded-5'>
                    <p><u><b>Ingenuity</b></u></p>
                    <p>
                        Whatever you need, our team will work hard to ensure the job gets done. We pride ourselves in delivering 
                        creative solutions for whatever you need. We are a technology consulting service, after all. We are not 
                        just software developers. We will find the best solution for you whether its creating a custom application,
                        modifying an exist one, or giving our geniune advice on what is already available in the market.
                    </p>
                </div>
          </div>
        </div>
        <hr className='line-break'/>
        <div className='headline'>
            <p><b>Founder</b></p>
        </div>
        <div className='founder-title'>
            <p><b>Darius Nwokike</b></p>
            <p className='greyed'><i>Founder, Owner Techsulting LLC</i></p>
        </div>
        <div className='flex-container'>
            <div className='flex-item-70 padded-right'>
                <p>
                    Darius Nwokike founded Techsulting LLC during the summer of 2022. Growing up, Darius always had a passion
                    in technology. He took this passion and recieved a degree in Computer Science from the United States Naval Academy
                    in 2021. There, he took several technology related interships including an cybersecurity intership with GE Aviation.
                    Currently, he serves as a supply officer (Ensign) in the United States Navy.
                </p>
                <p>
                    Darius is a skilled programmer who has heavy experience in Java, C++, and Javascript. In addition, he also knows
                    Python, C, and countless others. He also has experience in web-based platforms including Squarespace and Wix.
                </p>
                <p>
                    He was deep ties to the Charleston community as he was born and raised here. He is extremely grateful to the community
                    that raised him, and wants to repay the community by providing his technology skills.
                </p>
            </div>
            <div className='flex-item-30'>
                <img src={founderpic} alt='' width="100%" height="100%"/>
            </div>
        </div>
        <hr className='line-break'/>
        
    </div>
  )
}
